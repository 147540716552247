import React from 'react'
import Sidenav from './Sidenav'

export default function Games() {
  return (
    <>
      <Sidenav />
    </>
  )
}
