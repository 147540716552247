import React from 'react'

import FloatGui from '../FloatGui'
import Sidenav from './Sidenav'
export default function Gui() {
    return (
        <>
        <Sidenav/>
            {/* <FloatGui linkto="/"/> */}
        </>
    )
}
